import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CropperComponent } from 'angular-cropperjs';

@Component({
    selector: 'image-cropper-dialog',
    templateUrl: './image-cropper-dialog.component.html',
    styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
    @ViewChild(CropperComponent, { static: false }) public cropper: CropperComponent;

    @Input() public file: any;
    @Input() public filename: any;
    @Input() public cropConfig: any;

    public cropConfigDefault: any = {
        movable: true,
        scalable: true,
        zoomable: true,
        viewMode: 1,
        autoCrop: true,
        autoCropArea: 1,
        checkCrossOrigin: true,
        aspectRatio: 4 / 3,
        portrait: false,
        landscape: false
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ImageCropperDialogComponent>) {}

    ngOnInit() {
        //
        // Join crop config
        this.cropConfig = { ...this.cropConfigDefault, ...this.data?.cropConfig, ...this.cropConfig };

        //
        // Set data
        this.file = this.data.file;
        this.filename = this.data.filename;
    }

    finish() {
        //
        // Get cropped canvas
        this.cropper.cropper.getCroppedCanvas().toBlob(blob => {
            //
            // transform in file object
            const file = new File([blob], this.filename, {type: 'image/png'});

            //
            // Emit on crop event with file
            this.dialogRef.close({
                success: true,
                file: file
            });
        });
    }

    /**
     * Zoom in
     */
    zoomIn() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.zoom(0.1);
    }

    /**
     * Zoom out
     */
    zoomOut() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.zoom(-0.1);
    }

    /**
     * Go left
     */
    goLeft() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.move(-10, 0);
    }

    /**
     * Go right
     */
    goRight() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.move(10, 0);
    }

    /**
     * Go up
     */
    goUp() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.move(0, -10);
    }

    /**
     * Go down
     */
    goDown() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.move(0, 10);
    }

    /**
     * Rotate left
     */
    rotateLeft() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.rotate(-45);
    }

    /**
     * Rotate right
     */
    rotateRight() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.rotate(45);
    }

    /**
     * Reset
     */
    reset() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.reset();
    }

    /**
     * Set landscape
     */
    setLandscape() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Reset
        this.reset();

        //
        // Action
        this.cropper.cropper.setAspectRatio(this.cropConfig.landscape);
    }

    /**
     * Set portrait
     */
    setPortrait() {
        //
        // Validate
        if (!this.cropper || !this.cropper.cropper) return;

        //
        // Action
        this.cropper.cropper.setAspectRatio(this.cropConfig.portrait);
    }
}
